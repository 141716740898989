<template>
  <div id="app">
    <Modularization />
  </div>
</template>

<script>
import Modularization from "@/components/Modularization.vue"
export default {
  name: "App",
  components: {
    Modularization,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
#app {
  height: 100%;
}
</style>
