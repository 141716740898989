import {
    Button, Input, Tag, Row, Message
} from 'element-ui'
const element = {
    install: function (Vue) {
        Vue.use(Button)
        Vue.use(Input)
        Vue.use(Tag)
        Vue.use(Row)
        // 往vue实例原型里添加消息提示，方便全局调用
        Vue.prototype.$message = Message
        //使用：this.$message('这是一条消息提示');
    }
}
export default element