<template>
  <div class="content">
    <el-row>
      <el-button type="primary" @click="handleMessage">主要按钮</el-button>
    </el-row>
    <el-row>
      <el-button type="success">成功按钮</el-button>
    </el-row>
    <el-row>
      <el-input v-model="input" placeholder="请输入内容"></el-input>
    </el-row>
    <el-row>
      <el-tag type="success">标签Tag</el-tag>
    </el-row>
    <el-row> 修改 </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },
  methods: {
    handleMessage() {
      this.$message.warning("这是一条消息提示");
    },
  },
};
</script>

<style>
.content {
  padding: 20px;
  text-align: center;
}
</style>